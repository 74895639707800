@import (reference) 'bootstrap/less/variables.less';
@import (reference) 'variables.less';

/* Sticky footer. Based on:
 * http://stackoverflow.com/questions/19756997/how-to-create-a-sticky-footer-that-plays-well-with-bootstrap-3
 */
html, body {
  height: 100%;
  /* The html and body elements cannot have any padding or margin. */
}

.browserupgrade {
  // -20px is for margin-bottom of the panel
  margin: -20px 0 10px 0;
  background: #ff6565;
  color: #000;
  padding: 0.2em 0;
  font-weight: 500;
}

/* Wrapper for page content to push down footer */
#wrap {
  min-height: ~"calc(100vh - 70px)";
  height: auto;
  margin: 0 auto;
  padding: 0;
}

/* Set the fixed height of the footer here */
.footer {
  max-height: @footer-height;
  margin-top: 30px;
}

.footer-planitar {
  padding: 12px 0 12px 0;
  text-align: center;
  color: #aaa;
}

// Allocate all available height exluding top panel and the footer
.full-height {
  height: calc(~"100vh -" (@navbar-height + @footer-height + 60px));
}
.full-responsive-height {
  padding-bottom: ~"calc(100vh - 150px)";
}

.navbar {
  a.userinfo {
    padding: 5px;
  }

  img.userinfo {
    height: 40px;
    width: auto;
    padding: 0;
    margin: 0;
  }
}

.navbar-planitar {
  @navbar-link-color: darken(greyscale(@navbarLinkHoverColor), 5%);
  @navbar-link-bg-color: darken(@navbarBgColor, 7%);

  background-color: @navbarBgColor;
  border-color: fadeout(@navbarBgColor, 35%);
  margin-bottom: 0;

  a.navbar-brand {
    padding: 13px;
  }

  .navbar-header .navbar-toggle {
    border-color: @navbar-link-color;

    .icon-bar {
      background-color: @navbar-link-color;
    }

    &:hover, &:focus {
      background-color: @navbar-link-bg-color;
    }
  }

  .navbar-text,
  .navbar-nav > li > a,
  .navbar-link {
    color: @navbar-link-color;
  }

  .navbar-nav > li > a:hover,
  .navbar-link:hover {
    background-color: @navbar-link-bg-color;
    color: @navbarLinkHoverColor;
  }

  .navbar-nav > li > a:focus,
  .navbar-link:focus {
    background-color: @navbar-link-bg-color;
    color: #fff;
  }

  .navbar-nav > .open > a,
  .navbar-nav > .open > a:focus,
  .navbar-nav > .open > a:hover {
    background-color: @navbar-link-bg-color;
    color: @navbarLinkHoverColor;
  }

  .container {
    @media (max-width: 1000px) {
      width: 100%;
    }
  }

  @media (max-width: 767px) {
    .dropdown-menu > li > a {
      color: @navbar-link-color !important;
    }
  }

  .navbar-nav > li > a {
    @media (max-width: 1000px) {
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  ul.nav {
    li {
      margin: 0 5px;

      &.space {
        height: 1px;
        width: 30px;
      }

      a.golden {
        color: #fcca00;
      }
    }
  }
}

.body-planitar {
  width: 100%;
  height: auto;
  margin-top: 20px;

  &.nomargintop {
    margin-top: 0;
  }
}

.tos-warning {
  display: block;
  text-align: center;
  visibility: visible;
  margin-top: 0;
  position: relative;
  background: @alert-warning-bg;
  color: @alert-danger-text;
  font-size: 1.3em;
  padding: 30px 40px 30px 20px;
  border: @alert-warning-border 1px solid;
  overflow: hidden;

  &.highlight {
    background-color: red;
  }

  -webkit-transition: background-color 0.5s ease;
     -moz-transition: background-color 0.5s ease;
       -o-transition: background-color 0.5s ease;
          transition: background-color 0.5s ease;
}

.page-msg {
  font-size: 1.3em;
  font-weight: 200;
  margin-top: 100px;
  p {
    margin-top: 40px;
  }
}

.load-msg {
  font-size: 2em;
  font-weight: 200;
  margin: 100px auto 0 auto;
  width: 100px;
  p {
    margin-top: 40px;
  }
}

@spinner-container-zindex: 100;
@spinner-zindex: (@spinner-container-zindex + 20);

.center-spinner-absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.load-spinner {
  animation-duration: 3s;
  animation-name: rotate;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes rotate {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}

#load-spinner-container {
  &:extend(.center-spinner-absolute);
  max-width: 60px;
  max-height: 60px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  z-index: @spinner-zindex;
  #load-spinner {
    &:extend(.center-spinner-absolute);
    width: 80%;
    height: 80%;
    * {
      width: 100%;
      height: 100%;
      position: absolute;
    }
  }
}


h1.header {
  margin-bottom: 25px;
}

.icon-link {
  display: inline-block;
  color: inherit;
  text-decoration: none !important;

  &:hover {
    color: @link-color;
  }

  .fa {
    font-size: 1.3em;
  }
}

/*
.thumbnail-centered {
  &.outer {
    display: block;
    width: 100%;
    overflow: hidden;
  }
  &.inner {
    display: inline-block;
    position: relative;
    right: -50%;
    height: 100%;
    img {
      position: relative;
      left: -50%;
      height: 100%;
    }
  }
}
*/

.img-logo {
  height: 200px;
  width: auto;
}

.img-preview {
  min-height: 50px;
  max-height: 300px;
  max-width: 100%;
  width: auto;
}

.img-item {
  margin-bottom: 30px;
  overflow: hidden;
}

.plntr-header {
  .link {
    .icon-link();
    margin-right: 0.3em;
    /* Bootstrap's default margins for h1 */
    margin-top: 20px;
    margin-bottom: 10px;

    &:hover {
      color: #337ab7;
    }
  }
}

.spacer {
  &.top-0 {
    margin-top: 0;
  }
  &.top-10 {
    margin-top: 10px;
  }
  &.top-20 {
    margin-top: 20px;
  }
  &.top-30 {
    margin-top: 30px;
  }
  &.top-50 {
    margin-top: 50px;
  }

  &.bottom-0 {
    margin-bottom: 0;
  }
  &.bottom-5 {
    margin-bottom: 5px;
  }
  &.bottom-10 {
    margin-bottom: 10px;
  }
  &.bottom-20 {
    margin-bottom: 20px;
  }
  &.bottom-30 {
    margin-bottom: 30px;
  }
  &.bottom-50 {
    margin-bottom: 50px;
  }
}

// XXX Used in help messages defined in i18n
.ribbon-like {
  color: #fff;
  background-color: @default-view-color;
  padding: 0 2px;
}

.embed-autoheight {
  position: relative;
  display: block;
  padding: 0;
  overflow: hidden;

  & .embed-responsive-item,
  & embed,
  & iframe,
  & object,
  & video {
    width: 100%;
    border: 0;
  }
}

// social buttons
@import (reference) 'bootstrap/less/mixins/buttons.less';
@import 'bootstrap-social/bootstrap-social.less';
.btn-email {
  .btn-social(#f2f2f2, #808080);
}

// Material design adjustments
body.material-design {
  font-family: Roboto,sans-serif;
  font-size: 16px;
  line-height: 1.6;

  .navbar-planitar {
    // This corresponds to z-depth of 3 in material-ui library
    box-shadow: rgba(0, 0, 0, 0.188235) 0px 10px 30px, rgba(0, 0, 0, 0.227451) 0px 6px 10px,
  }
  .body-planitar {
    margin-top: 0;
  }
}

// Override bootstrap css with our new color
.btn.btn-primary {
  background-color: @brand-primary;

  &:hover {
    background-color: darken(@brand-primary, 5%);
  }
}

// Prevent number input arrow buttons to fix bug in Portal where the buttons get "stuck"
// See https://github.com/PlanitarInc/wi-app/pull/1250
// Chrome, Safari, Edge, Opera
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
// Firefox
input[type=number] {
  -moz-appearance: textfield;
}

.toast-alert {
  // Show on top of navbar
  z-index: @zindex-navbar + 1;
}
