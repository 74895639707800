@import (reference) 'bootstrap/less/variables.less';
@import (reference) 'bootstrap/less/mixins/text-emphasis.less';
@import (reference) 'bootstrap/less/mixins/buttons.less';

.container-wide {
  width: 100%;
}

.btn {
  overflow: hidden;
}

.btn-file {
  position: relative;
  overflow: hidden;
}

.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}

// Mix of bootstrap's `.text-emphasis-variant()` and `.button-variant()`
.btn-link-variant(@color) {
  color: @color;
  font-weight: normal;
  border-radius: 0;

  &,
  &:active,
  &.active,
  &[disabled],
  fieldset[disabled] & {
    background-color: transparent;
    // XXX
    //.box-shadow(none);
  }
  &,
  &:hover,
  &:focus,
  &:active {
    border-color: transparent;
  }
  &:hover,
  &:focus {
    color: darken(@color, 10%);
    background-color: transparent;
  }
    // XXX
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus {
      color: @btn-link-disabled-color;
      text-decoration: none;
    }
  }
}

.btn-link.btn-primary {
  .btn-link-variant(@brand-primary);
}
.btn-link.btn-success {
  .btn-link-variant(@state-success-text);
}
.btn-link.btn-info {
  .btn-link-variant(@state-info-text);
}
.btn-link.btn-warning {
  .btn-link-variant(@state-warning-text);
}
.btn-link.btn-danger {
  .btn-link-variant(@state-danger-text);
}

/* From https://angular-ui.github.io/bootstrap/:
 * Original Bootstrap's CSS depends on empty href attributes to style cursors
 * for several components (pagination, tabs etc.). But in AngularJS adding
 * empty href attributes to link tags will cause unwanted route changes. This
 * is why we need to remove empty href attributes from directive templates and
 * as a result styling is not applied correctly. The remedy is simple, just add
 * the following styling to your application:
 */
.nav, .pagination, .carousel, .panel-title a, .alert a, .list-group a {
  cursor: pointer;
}

.list-group-item.btn-sm {
  padding: 5px 10px;
}

.link {
  cursor: pointer;

  &.panel:hover {
    background-color: #eee;
  }
}

.row.space-bottom {
  margin-bottom: 30px;
}

.center-block {
  float: none;
  margin: 0 auto;
}

.checkbox.indent {
  margin-left: 15px;
}

.top-buffer {
  margin-top: 30px;
}

.modal-content .modal-body.scrollable {
  overflow-y: auto;

  // XXX reuse bootstrap variable
  @modal-margin: 30px;
  @modal-header-height: 65px;
  @modal-footer-height: 65px;
  @occupied-space: (2*@modal-margin + @modal-header-height + @modal-footer-height);
  max-height: calc(~"100vh -" @occupied-space);
}

.in-place-modal {
  .modal {
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    z-index: 1;
    display: block;
  }

  .modal-dialog {
    left: auto;
    margin-right: auto;
    margin-left: auto;
  }
}

.glyphicon-refresh.animate {
  -webkit-animation-name: rotateThis;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
}

@-webkit-keyframes 'rotateThis' {
  from {
    -webkit-transform: rotate( 0deg );
  }
  to {
    -webkit-transform: rotate( 360deg );
  }
}

.badge {
  &.badge-success {
    background-color: #5cb85c;
  }
  &.badge-info {
    background-color: #5bc0de;
  }
  &.badge-warning {
    background-color: #f0ad4e;
  }
  &.badge-danger {
    background-color: #d9534f;
  }
  // Custom colors
  &.badge-purple {
    background-color: #aa5cb8;
  }
  &.badge-violet {
    background-color: #775cb8;
  }
  &.badge-orange {
    background-color: #e86010
  }
  &.badge-pink {
    background-color: #dc3dd7;
  }
}

.label {
  // Custom colors
  &.label-purple {
    background-color: #aa5cb8;
  }
  &.label-violet {
    background-color: #775cb8;
  }
  &.label-orange {
    background-color: #e86010
  }
  &.label-pink {
    background-color: #dc3dd7;
  }
}

.btn {
  // Custom colors
  &.btn-purple {
    .button-variant(#fff, #aa5cb8, #aa5cb8);
  }
  &.btn-violet {
    .button-variant(#fff, #775cb8, #775cb8);
  }
  &.btn-orange {
    .button-variant(#fff, #e86010, #e86010);
  }
  &.btn-pink {
    .button-variant(#fff, #dc3dd7, #dc3dd7);
  }
}

.flex-row {
  margin-right: -15px;
  margin-left: -15px;

  // Fallback for IE9 and lower
  display: inline-block;
  // Based on https://css-tricks.com/using-flexbox/
  display: -webkit-box;  /* OLD - iOS 6-, Safari 3.1-6 */
  display: -webkit-flex; /* NEW - Chrome */
  display:    -moz-box;     /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;  /* TWEENER - IE 10 */
  display:         flex;
  -ms-box-orient: horizontal;
  -webkit-flex-flow: row wrap;
     -moz-flex-flow: row wrap;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-align-items: center;
          align-items: center;
}

.flex-col-md-6 {
  padding-right: 15px;
  padding-left: 15px;
  min-height: 1px;
  position: relative;
  width: 50%;

  @media screen and (max-width: 991px) {
    width: 100%;
  }
}

.help-block.inline {
  display: inline-block;
}

// A feedback for a default bootstrap input with an addon
// is hidden when it is active. This makes sure that the
// feedback stays visible, even when the input is active.
.form-control-feedback.always-visible {
  z-index: 5;
}

figure.overlay {
  position: relative;
  line-height: 0;
  max-width: 100%;

  @text-color: #FFF;
  @bg-color: #000;

  .img-responsive {
    max-width: 100%;
    height: auto;
  }

  figcaption {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    line-height: 1em;
    color: fade(@text-color, 40%);
    background-color: fade(@bg-color, 20%);

    -webkit-transition: all 0.7s ease;
       -moz-transition: all 0.7s ease;
        -ms-transition: all 0.7s ease;
         -o-transition: all 0.7s ease;
            transition: all 0.7s ease;


    a, .link {
      color: inherit;

      &:hover {
        color: @text-color;
      }
    }

    & .overlay-cover {
      position: absolute;
      height: 100%;
      width: 100%;

      &.icon {
        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 7rem;
        &.small { font-size: 3rem; }

        * {
          margin-left: 5px;
          margin-right: 5px;
        }
      }

    }

    &:hover {
      color: fade(@text-color, 70%);
      background-color: fade(@bg-color, 80%);
    }

  }
}

.hidden-overflow {
  overflow: hidden;
}
